import * as React from "react";
import classnames from "classnames";
import * as Intl from "react-intl";

import useWidth from "msem-lib/es/hooks/use-width.js";
import { createGMTDate } from "msem-lib/es/services/utils";
import WarnIcon from "msem-lib/es/icons/warn";
import ChevronLeftIcon from "msem-icons/es/chevron-left.js";
import ChevronRightIcon from "msem-icons/es/chevron-right.js";
import Button from "msem-ui/es/button";

import configuration from "../../services/config";
import * as Stay from "../../services/stay";
import css from "./date-picker.module.css";

type MonthDates = {
  month: number;
  year: number;
  dates: Date[];
};

type MonthWeeks = {
  month: number;
  year: number;
  weeks: Date[][];
};

type Months<T> = {
  [key: string]: T;
};

export type SelectionChangedEvent = {
  start: Date;
  stop: Date;
};

type Selection = {
  start?: Date;
  stop?: Date;
};

type MonthProps = {
  month: string;
  months: Months<MonthWeeks>;
  children: React.ReactNode[];
  horizontal: boolean | null;
  scrollTopGlobal: number;
  setCurrentMonth: (month: string) => void;
};

type WeekProps = {
  weekIndex: number;
  children: React.ReactNode[];
};

type StayDates = {
  from?: Date;
  to?: Date;
};

type ForceFocus = "CHECKIN" | "CHECKOUT";

type DatePickerProps = {
  scrollableRef?: React.RefObject<HTMLDivElement>;
  selected?: Selection;
  selectionChanged: ({ start, stop }: { start: Date; stop: Date }) => void;
  close: () => void;
  rangePicker?: boolean;
  from: Date;
  to: Date;
  extendableStay?: boolean;
  presetedDates?: StayDates;
  focusedDate?: ForceFocus;
  B2B?: boolean;
  isGescoOperator?: boolean;
  merchant?: string;
  isPreview?: boolean;
};

const { apiLodging: API_URL } = configuration();

const GAP_BETWEEN_MONTHS = 14;
const MAX_WIDTH = 560;

const fetcher = async ({ merchant, start, numberDays }: { merchant: string; start: string; numberDays: number }) => {
  const url = `${API_URL}/${merchant}/availabilities`;
  const response = await fetch(url, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ start, numberDays, stayDurations: [1, 2, 3, 4, 5, 6, 7, 14, 21, 28] }),
  });
  if (response.ok) {
    const result = await response.json();
    return result;
  }
  throw new Error(`failed to fetch avalaibilities for lodging ${merchant}`);
};

export default function DatePicker({
  scrollableRef,
  selected = {},
  selectionChanged,
  close,
  rangePicker,
  from,
  to,
  extendableStay,
  presetedDates,
  focusedDate,
  B2B = false,
  isGescoOperator = false,
  merchant,
  isPreview = false,
}: DatePickerProps) {
  const intl = Intl.useIntl();
  const dayRef = React.useRef<HTMLButtonElement>(null);
  const monthRef = React.useRef<HTMLDivElement>(null);
  const calendarRef = React.useRef<HTMLDivElement>(null);
  const container = React.useRef<HTMLDivElement>(null);
  const width = useWidth(container);
  const today = new Date();
  const months = React.useMemo(() => buildMonths(from, to), [from, to]);
  const defaultCurrentMonth = selected?.start
    ? `${selected?.start.getFullYear()}-${selected?.start.getMonth()}`
    : Object.keys(months)[0];
  const [selection, setSelection] = React.useState(selected);
  const [scrollTopGlobal, setScrollTopGlobal] = React.useState<number>(0);
  const [widthToScroll, setWidthToScroll] = React.useState(0);
  const [previousIsAvailable, setPreviousIsAvailable] = React.useState(false);
  const [nextIsAvailable, setNextIsAvailable] = React.useState(true);
  const [horizontal, setHorizontal] = React.useState<boolean | null>(null);
  const [forceFocus, setForceFocus] = React.useState(focusedDate);
  const [stays, setStays] = React.useState<StayDates[]>([]);
  const [hasData, setHasData] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = React.useState<string | null>(null);

  React.useEffect(() => {
    const run = () => {
      // on initialize currentMonth ici pour éviter 2 appels de suite au démarrage
      setCurrentMonth(defaultCurrentMonth);
    };
    run();
  }, []);

  React.useEffect(() => {
    if (currentMonth) fetchAvailabilities();
  }, [currentMonth]);

  React.useEffect(() => {
    setHorizontal(width >= MAX_WIDTH);
  }, [width]);

  React.useEffect(() => {
    const selectedDay = dayRef?.current; // si une date est renseignée, on se rend sur le bon mois
    if (horizontal) {
      const scrollable = calendarRef?.current;
      if (scrollable) {
        const widthToScroll = (scrollable.clientWidth + GAP_BETWEEN_MONTHS) / 2;
        setWidthToScroll(widthToScroll);
        if (selectedDay) {
          const offset = Math.trunc(selectedDay.offsetLeft / widthToScroll);
          scrollable.scrollTo({
            top: 0,
            left: offset * widthToScroll,
            behavior: "instant",
          });
        }
      }
    } else {
      const scrollable = scrollableRef?.current;
      if (scrollable && selectedDay) {
        const monthElement = selectedDay?.parentElement?.parentElement;
        if (monthElement) {
          scrollable.scrollTo({
            top: monthElement.offsetTop - 30,
            left: 0,
            behavior: "instant",
          });
        }
      }
    }
  }, [calendarRef.current, width, scrollableRef, horizontal]);

  React.useEffect(() => {
    // pour la version verticale
    if (scrollableRef?.current)
      scrollableRef?.current.addEventListener("scroll", (e: any) => {
        setScrollTopGlobal(e.target.scrollTop);
      });
    return () => {
      if (scrollableRef?.current)
        scrollableRef?.current.removeEventListener("scroll", (e: any) => {
          setScrollTopGlobal(e.target.scrollTop);
        });
    };
  }, []);

  const handleScroll = React.useCallback(
    // pour la version horizontale
    async (e: any) => {
      if (calendarRef?.current && widthToScroll > 0 && horizontal) {
        const offset = calcOffset(calendarRef.current.scrollLeft, widthToScroll);
        const scrollLeft = calendarRef.current.scrollLeft;
        setTimeout(() => {
          const _offset = calcOffset(scrollLeft, widthToScroll);
          if (offset === _offset) setCurrentMonth(Object.keys(months)[offset]);
        }, 500); // on considère qu'au bout d'.5 seconde, c'est un scrollEnd
      }

      if (e.target.scrollLeft === 0) setPreviousIsAvailable(false);
      else setPreviousIsAvailable(true);

      if (e.target.scrollLeft + calendarRef.current?.clientWidth === e.target.scrollWidth) setNextIsAvailable(false);
      else setNextIsAvailable(true);
    },
    [calendarRef.current, setNextIsAvailable, widthToScroll, horizontal]
  );

  const fetchAvailabilities = React.useCallback(async () => {
    if (!merchant || isLoading === true || !currentMonth) return;
    setIsLoading(true);
    const { start, numberDays, gottenMonths } = Stay.getStartAndNumberDays(currentMonth, hasData);

    if (start === null || numberDays === 0) {
      setIsLoading(false);
      return;
    }

    setHasData([...hasData, ...gottenMonths]);
    try {
      const { stays: _stays } = await fetcher({ merchant, start: formatDate(start), numberDays });
      if (_stays?.length) {
        const formattedStays = _stays.map(({ start, end }: { start: string; end: string }) => {
          return Stay.deserializeDates({ from: start, to: end });
        });
        setStays([...stays, ...formattedStays]);
      }
    } catch (e) {
      console.log(e, "error");
    }
    setIsLoading(false);
  }, [hasData, setHasData, isLoading, setIsLoading, stays, setStays, currentMonth]);

  const previousMonth = React.useCallback(async () => {
    if (previousIsAvailable && calendarRef.current && isLoading === false) {
      calendarRef.current.scrollTo({
        top: 0,
        left: (calcOffset(calendarRef.current.scrollLeft, widthToScroll) - 1) * widthToScroll,
        behavior: "smooth",
      });
    }
  }, [previousIsAvailable, calendarRef.current, isLoading, widthToScroll]);

  const nextMonth = React.useCallback(async () => {
    if (nextIsAvailable && calendarRef.current && isLoading === false) {
      calendarRef.current.scrollTo({
        top: 0,
        left: (calcOffset(calendarRef.current.scrollLeft, widthToScroll) + 1) * widthToScroll,
        behavior: "smooth",
      });
    }
  }, [nextIsAvailable, calendarRef.current, isLoading, widthToScroll]);

  const changeSelection = (start: Date, stop?: Date) => {
    setSelection({ start, stop });
  };

  const rangeClicked = (day: Date) => {
    const { start, stop } = selection;
    if (start && (!stop || forceFocus === "CHECKOUT") && start <= day) changeSelection(start, day);
    else changeSelection(day);
  };

  const resetSelection = () => {
    setForceFocus(undefined);
    setSelection({});
  };

  const resetStop = () => {
    setSelection({ ...selection, stop: undefined });
  };

  const clicked = (day: Date) => () => {
    rangePicker ? rangeClicked(day) : changeSelection(day);
  };

  const validated = () => {
    const { start, stop } = selection;
    if (start && stop && !isEqual(selected, selection)) {
      selectionChanged({ start, stop });
    }
    close();
  };

  const isExtendableStayValid = () => {
    if (selection.start === undefined) return false;

    if (extendableStay && presetedDates && presetedDates.from && presetedDates.to) {
      const validStart = formatDate(selection.start) <= formatDate(presetedDates.from);
      if (!validStart) return false;
      const stop = selection?.stop ? formatDate(selection.stop) : undefined;
      return stop && stop >= formatDate(presetedDates.to);
    }

    return true;
  };

  const shouldDisableButton = () => {
    if (selection.start === undefined || selection.stop === undefined) return true;
    return isExtendableStayValid() ? false : true;
  };

  const warning =
    Stay.readDates() && Stay.readCartId()
      ? intl.formatMessage({ id: isGescoOperator ? "warning_gesco_operator" : "warning" })
      : undefined;

  const warningDates =
    isGescoOperator && selection.start && selection.start < today
      ? intl.formatMessage({
          id:
            (selection.stop || selection.start) < today
              ? "warning_gesco_operator_past"
              : "warning_gesco_operator_from_past",
        })
      : undefined;

  return (
    <div ref={container}>
      <div className={css.stickyHeader}>
        <div className={css.header}>
          {horizontal && <span className={css.titleHeader}>{intl.formatMessage({ id: "stayDatesLabel" })}</span>}
          <div className={css.helpers}>
            <div
              onClick={resetSelection}
              className={classnames(css.helper, css.helperStart, {
                [css.active]: !selection?.start || (forceFocus === "CHECKIN" && selection?.stop),
              })}
            >
              {intl.formatMessage({ id: "checkIn" })}{" "}
              <span>
                {selection?.start ? intl.formatDate(selection?.start) : intl.formatMessage({ id: "addDate" })}
              </span>
            </div>
            <div
              onClick={resetStop}
              className={classnames(css.helper, css.helperEnd, {
                [css.active]: (selection?.start && !selection?.stop) || forceFocus === "CHECKOUT",
              })}
            >
              {intl.formatMessage({ id: "checkOut" })}{" "}
              <span>{selection?.stop ? intl.formatDate(selection?.stop) : intl.formatMessage({ id: "addDate" })}</span>
            </div>
          </div>
        </div>

        {(warning || warningDates) && (
          <div className={css.warning}>
            <div className={css.warningSign}>
              <WarnIcon />
            </div>
            <span className={css.warningLabel}>
              {warning}
              {warning && warningDates && <br />}
              {warningDates}
            </span>
          </div>
        )}
      </div>
      <div className={css.calendar}>
        {horizontal && (
          <div className={css.control}>
            <ChevronLeftIcon
              onClick={previousMonth}
              className={classnames(css.controlChevron, { [css.deactivated]: !previousIsAvailable })}
            />
            <ChevronRightIcon
              onClick={nextMonth}
              className={classnames(css.controlChevron, { [css.deactivated]: !nextIsAvailable })}
            />
          </div>
        )}
        <div
          className={classnames(css.months, { [css.horizontal]: horizontal })}
          ref={calendarRef}
          onScroll={handleScroll}
        >
          {Object.keys(months).map((month, i) => {
            const target = months[month];
            const filteredStays = stays.filter(({ from, to }) => {
              return (
                (from?.getFullYear() === target.year && from?.getMonth() === target.month) ||
                (to?.getFullYear() === target.year && to?.getMonth() === target.month)
              );
            });
            return (
              <div key={month} ref={monthRef}>
                <Month
                  month={month}
                  months={months}
                  scrollTopGlobal={scrollTopGlobal}
                  setCurrentMonth={setCurrentMonth}
                  horizontal={horizontal}
                >
                  <WeekDays />
                  {months[month].weeks.map((week: Date[], weekIndex: number) => (
                    <Week key={weekIndex} weekIndex={weekIndex}>
                      {fillWeek(week).map((day, dayIndex) => {
                        const passedDay = isBeforeToday(day);

                        const isStart = isStartDay(day, selection);
                        const isAvailable =
                          merchant &&
                          day &&
                          filteredStays.length > 0 &&
                          isAvailableDay(filteredStays, day, selection, forceFocus);
                        const isUnavailable = merchant && day && !isAvailable;
                        const start = selection.start;
                        const ref = isStart || (!start && isToday(day)) ? dayRef : undefined;
                        const buttonClasses = classnames(css.day, {
                          [css.deactivated]: passedDay && !isPreview && !isGescoOperator,
                          [css.selected]: isSelected(day, selection),
                          [css.stop]: isStopDay(day, selection),
                          [css.start]: isStart,
                          [css.unavailable]: isUnavailable,
                          [css.available]: isAvailable,
                        });
                        return day ? (
                          <button
                            type="button"
                            key={dayIndex}
                            className={buttonClasses}
                            ref={ref}
                            onClick={
                              (!passedDay || isPreview || isGescoOperator) &&
                              (!merchant || (isAvailable && !isUnavailable))
                                ? clicked(day)
                                : undefined
                            }
                          >
                            <span>{day.getDate()}</span>
                          </button>
                        ) : (
                          <span key={dayIndex} className={css.day} />
                        );
                      })}
                    </Week>
                  ))}
                </Month>
              </div>
            );
          })}
        </div>
      </div>
      <div className={css.footer}>
        {selection.start && !isExtendableStayValid() && (
          <div className={css.stayMessage}>
            {intl.formatMessage(
              { id: "extendableStayError" },
              { from: formatDateForUser(presetedDates?.from), to: formatDateForUser(presetedDates?.to) }
            )}
          </div>
        )}
        <div className={css.stayActions}>
          <Button onClick={close} size="S" variant="tertiary">
            {intl.formatMessage({ id: "cancelDatePicker" })}
          </Button>
          <div className={css.rightButtons}>
            <Button disabled={shouldDisableButton()} onClick={validated} size="S">
              {B2B
                ? intl.formatMessage({ id: "validateDatePickerB2B" })
                : intl.formatMessage({ id: "validateDatePicker" })}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function WeekDays() {
  const intl = Intl.useIntl();
  return (
    <div className={classnames(css.weekHeader, css.week)}>
      {Array.from({ length: 7 }).map((_, index) => {
        const date = createGMTDate("2020-01-13"); // FYI : A random monday
        date.setDate(date.getDate() + index);
        return (
          <div key={index} className={css.weekDay}>
            {intl.formatDate(date, { weekday: "short" })}
          </div>
        );
      })}
    </div>
  );
}

function Month({ month, months, scrollTopGlobal, horizontal, setCurrentMonth, children }: MonthProps) {
  const intl = Intl.useIntl();
  const target = months[month];
  const monthNumber = (target.month + 1).toString().padStart(2, "0");
  const date = createGMTDate(`${target.year}-${monthNumber}-01`);
  const title = intl.formatDate(date, { month: "long", year: "numeric" });
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (
      horizontal === false &&
      ref?.current &&
      scrollTopGlobal >= ref.current?.offsetTop &&
      scrollTopGlobal < ref.current?.offsetTop + ref.current?.offsetHeight
    )
      setCurrentMonth(`${date.getFullYear()}-${date.getMonth()}`);
  }, [scrollTopGlobal]);
  return (
    <div ref={ref}>
      <React.Fragment key={month}>
        <span className={css.monthTitle}>{title}</span>
        <div className={css.month}>{children}</div>
      </React.Fragment>
    </div>
  );
}

function Week({ weekIndex, children }: WeekProps) {
  return (
    <div className={css.week} key={weekIndex}>
      {children}
    </div>
  );
}

function isEqual(a: Selection, b: Selection) {
  return a?.start?.getTime() === b?.start?.getTime() && a?.stop?.getTime() === b?.stop?.getTime();
}

function formatDate(date: Date) {
  const yy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  return `${yy}-${mm}-${dd}`;
}

function formatDateForUser(date?: Date) {
  if (!date) return;
  const yy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  return `${dd}/${mm}/${yy}`;
}

function buildRange(from: Date, to: Date) {
  const range = [];
  from.setDate(1);
  const f = createGMTDate(from);
  while (f <= to) {
    const current = createGMTDate(f);
    range.push(current);
    f.setDate(f.getDate() + 1);
  }
  return range;
}

function buildWeeks(month: MonthDates): MonthWeeks {
  return {
    year: month.year,
    month: month.month,
    weeks: month.dates?.reduce((acc: Date[][], date) => {
      const count = acc.length;
      const day = date.getDay();
      return day === 1
        ? [...acc, [date]]
        : count === 0
        ? [...acc, [date]]
        : acc.map((week, index) => (index === count - 1 ? [...week, date] : week));
    }, []),
  };
}

function buildMonths(from: Date, to: Date): Months<MonthWeeks> {
  const range = buildRange(from, to);
  const months = range.reduce((acc: Months<MonthDates>, date) => {
    const month = date.getMonth();
    const year = date.getFullYear();
    const key = `${year}-${month}`;
    return acc[key]
      ? { ...acc, [key]: { ...(acc[key] as MonthDates), dates: [...acc[key].dates, date] } }
      : { ...acc, [key]: { ...(acc[key] as MonthDates), month, year, dates: [date] } };
  }, {});

  return Object.keys(months).reduce((acc: Months<MonthWeeks>, key: string) => {
    return { ...acc, [key]: buildWeeks(months[key]) };
  }, {});
}

function fillWeek(week: Date[]) {
  if (week.length === 7) return week;
  const first = week[0];
  const firstDay = (first.getDay() + 6) % 7;
  const filled: (Date | null)[] = [...week];
  for (let i = 0; i < firstDay; i++) filled.unshift(null);
  for (let i = filled.length; i < 7; i++) filled.push(null);
  return filled;
}

function isSelected(day: Date | null, selection: Selection) {
  const { start, stop } = selection;
  if (!start || !day) return false;
  const ref = formatDate(day);
  return (!stop && ref === formatDate(start)) || (stop && ref >= formatDate(start) && ref <= formatDate(stop));
}

function isToday(day: Date | null) {
  return day && formatDate(day) === formatDate(createGMTDate(new Date()));
}

function isBeforeToday(day: Date | null) {
  return day && formatDate(day) < formatDate(createGMTDate(new Date()));
}

function isStartDay(day: Date | null, selection: Selection) {
  const { start } = selection;
  return day && start && formatDate(day) === formatDate(start);
}

function isStopDay(day: Date | null, selection: Selection) {
  const { start, stop } = selection;
  return day && stop
    ? formatDate(day) === formatDate(stop)
    : day && start
    ? formatDate(day) === formatDate(start)
    : false;
}

function isAvailableDay(stays: StayDates[], day: Date, { start, stop }: Selection, forceFocus?: ForceFocus) {
  return (
    stays.length > 0 &&
    stays.some((stay) => {
      if (!stay?.from || !stay?.to) return false;
      if (!start || (start && stop && forceFocus !== "CHECKOUT")) return day.getTime() === stay.from.getTime();
      if (start && (!stop || forceFocus === "CHECKOUT"))
        return start.getTime() === stay.from.getTime() && day.getTime() === stay.to.getTime();
      return false;
    })
  );
}

function calcOffset(scrollLeft: number, widthToScroll: number) {
  if (scrollLeft === 0 || widthToScroll === 0) return 0;
  return Math.ceil(scrollLeft / widthToScroll);
}
